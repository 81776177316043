/**
 * Created by Paul on 27/07/2016.
 */

function buildUserArray(data)
{
    var items = [];

    if(data.length > 0) {

        items.push('<tr><th>' +
            'Name' +
            '</th>' +
            '<th>' +
            'VI' +
            '</th>' +
            '<th>' +
            'Email' +
            '</th>' +
            '<th>' +
            'Categories' +
            '</th>' +
            '<th>' +
            ' ' +
            '</th>' +
            '</tr>'
        );

        $.each(data, function (key, val) {

            items.push('<tr><td>' +
                '<a href="' + window.urlBase + '/users/' + val.id + '">' + val.fullname + '</a>' +
                '</td>' +
                '<td>' +
                (1 == val.medicalImpedimentsVisual ? 'Y' : 'N' ) +
                '</td>' +
                '<td>' +
                val.email +
                '</td>' +
                '<td>' +
                val.tags +
                '</td>' +
                '<td>' +
                '<a href="' + window.urlBase + '/start-user-journey/' + val.id + '">Start booking</a>' +
                '</td>' +
                '</tr>'
            );


        });

    }else{

        items.push('<tr><td>No matches found</td></tr>');

    }

    return items;

}

function buildHolidayArray(data)
{

    var items = [];

    if(data.length > 0) {


        $.each(data, function (key, val) {

            items.push('<tr><td>' +
                '<a href="' + window.urlBase + '/holidays/' + val.id + '">' + val.name + '</a>' +
                '</td>' +
                '<td>' +
                val.starts_at +
                '</td>' +
                    /*
                     '<td>' +
                     '<a href="' + window.urlBase + '/start-holiday-journey/' + val.id + '">Start booking</a>' +
                     '</td>' +
                     */
                '</tr>'
            );
        });

    }else{

        items.push('<tr><td>No matches found</td></tr>');

    }

    return items;

}

function buildArrangementArray(data)
{
    var items = [];

    $.each(data, function (key, val) {

        items.push('<tr><td>' +
            '<a href="' + window.urlBase + '/bookings/' + val.id + '">' + val.ref + '</a>' +
            '</td>' +
            '<td>' +
            val.fullname +
            '</td>' +
            '</tr>'
        );
    });

    return items;

}


$( document ).ready(function() {

    $('#search').bind('change keyup paste', function () {

        // https://laravel.com/docs/master/csrf
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });


        var query = $('#search').val();

        var needle = $('input[name=needle]:checked').val();
        //alert(needle);

        if(query.length >= 3) {


            //$.post(window.url, {transport: 'ajax', needle: window.needle, search: query}, function (data) {
            $.post(window.url, {transport: 'ajax', needle: needle, search: query}, function (data) {

                $('#' + window.targetId).empty();

                var items = [];


                switch(window.scope){
                    case 'users':
                        items = buildUserArray(data);
                        break;
                    case 'holidays':
                        items = buildHolidayArray(data);
                        break;
                    case 'arrangements':
                        items = buildArrangementArray(data);
                        break;
                }

                if (items.length > 0) {
                    $('<table/>', {
                        'class': 'ajax-block table table-striped table-bordered',
                        'id': 'ajax-block-user',
                        html: items.join('')
                    }).appendTo('#' + window.targetId);
                }


            }, "json");

        }

        console.log('done');

    });
});